import { Injectable } from '@angular/core';
import { share, timer, tap, map, ReplaySubject, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AuthenticatorService } from './authenticator.service';
import { Response } from '../models/response.interface';
import { environment } from 'src/environments/environment';
import { BrandDto } from '../models/entities/brand.model';
import { CustomerDto } from '../models/entities/customer.model';
import { CurrencyDto } from '../models/entities/currency.model';
import { ConstantPanelDto } from '../models/entities/constant-panel.model';
import { User } from '../models/user.interface';
import { RoleDto } from '../models/entities/role.model';
import { UserDto } from '../models/entities/user.model';

export const CACHE_TIMEOUT = 1000 * 60 * 60;

@Injectable({
	providedIn: 'root'
})
export class LookupService {
	constructor(private authenticatorService: AuthenticatorService) {}

	getLookupObservable<T>(url: string, cacheTimeout: number = CACHE_TIMEOUT): Observable<T[]> {
		if (this.authenticatorService.isAccessTokenExpired) {
			location.href = '/identity/login';
			throw new Error('Access token expired');
		}
		return ajax<Response<T[]>>({
			url: `${environment.API_URL}api/${url}`,
			method: 'GET',
			headers: {
				Authorization: `Bearer ${this.authenticatorService.tokenSubject.value}`
			}
		}).pipe(
			tap(() => {}),
			map((response) => {
				return response.response.data;
			}),
			share({
				connector: () => new ReplaySubject(1),
				resetOnComplete: () => timer(CACHE_TIMEOUT)
			})
		);
	}

	private _users$!: Observable<UserDto[]>;
	get users$() {
		if (!this._users$) {
			this._users$ = this.getLookupObservable<UserDto>('user');
		}
		return this._users$;
	}

	private _roles$!: Observable<RoleDto[]>;
	get roles$() {
		if (!this._roles$) {
			this._roles$ = this.getLookupObservable<RoleDto>('role');
		}
		return this._roles$;
	}

	private _brands$!: Observable<BrandDto[]>;
	get brands$() {
		if (!this._brands$) {
			this._brands$ = this.getLookupObservable<BrandDto>('brand');
		}
		return this._brands$;
	}

	private _customers$!: Observable<CustomerDto[]>;
	get customers$() {
		if (!this._customers$) {
			this._customers$ = this.getLookupObservable<CustomerDto>('customer');
		}
		return this._customers$;
	}

	get currencies$() {
		var currencies: CurrencyDto[] = [
			{
				id: 1,
				name: 'Türk Lirası',
				code: 'TRY',
				symbol: '₺'
			},
			{
				id: 2,
				name: 'Euro',
				code: 'EUR',
				symbol: '€'
			},
			{
				id: 3,
				name: 'Dolar',
				code: 'USD',
				symbol: '$'
			}
		];
		return of(currencies);
	}

	private _constantPanels$!: Observable<ConstantPanelDto[]>;
	get constantPanels$() {
		if (!this._constantPanels$) {
			this._constantPanels$ = this.getLookupObservable<ConstantPanelDto>('constant-panel');
		}
		return this._constantPanels$;
	}

	private _materialCategories$!: Observable<any[]>;
	get materialCategories$() {
		if (!this._materialCategories$) {
			this._materialCategories$ = this.getLookupObservable<any>('material/category');
		}
		return this._materialCategories$;
	}
}
